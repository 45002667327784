<template>
    <div>
        <div
            class="mt-6 flex flex-col gap-4 rounded-xl bg-grind p-8 text-white"
        >
            <div class="text-xl font-bold">Not sure where to start?</div>
            <div>
                We're here to help! From selecting the best packaging solution
                for your product, to offering design help—and everything in
                between.
            </div>
            <ValidatedForm
                v-if="!success"
                ref="formRef"
                :form-errors="formErrors"
                form-error-class="text-grind"
                :show-required-fields-message="true"
            >
                <TextInput
                    v-model="email"
                    name="email"
                    type="text"
                    label="Enter Email Address *"
                    :submitting="submitting"
                    :form-errors="formErrors"
                    container-class="w-full mb-4 text-white"
                    input-class="floating-label-grind"
                    required
                />
                <TextareaInput
                    v-model="message"
                    name="message"
                    label="How Can We Help? *"
                    :submitting="submitting"
                    :form-errors="formErrors"
                    container-class="w-full text-white"
                    input-class="floating-label-grind"
                    required
                />
                <input type="hidden" name="page" :value="pageName" />
            </ValidatedForm>
            <LoadingButton
                v-if="!success"
                type="submit"
                class="btn-secondary-inverse"
                :loading="submitting"
                @click="validateAndSubmit()"
            >
                Submit
            </LoadingButton>
            <div class="text-grind">
                <Alert v-if="success" type="success" :dismissable="false">
                    Your information was successfully submitted. A Roastar team
                    member will reach out to you via email within 3-5 business
                    days.
                </Alert>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import TextInput from '~/components/forms/TextInput.vue';
import TextareaInput from '~/components/forms/TextareaInput.vue';
import ValidatedForm from '~/components/forms/ValidatedForm.vue';
import { sendDataLayerFormEvent } from '~/composables/useDataLayer';

const props = defineProps({
    pageName: {
        type: String,
        required: true,
    },
});

const formRef = ref<InstanceType<typeof ValidatedForm> | null>(null);

const state = reactive({
    name: '',
    email: '',
    message: '',
    submitting: false,
    success: false,
    formErrors: {},
});

const { name, email, message, submitting, success, formErrors } = toRefs(state);

const validateAndSubmit = async () => {
    if (formRef.value?.validate()) {
        state.submitting = true;

        await useAuth()
            .apiRequest('POST', 'contact-us-ajax', formRef.value?.getFormData())
            .then((response) => {
                state.formErrors = {};
                state.success = true;
                state.name = '';
                state.email = '';
                state.message = '';

                sendDataLayerFormEvent('form_submit', 'Contact Us', 'industry-cta');
            })
            .catch((error) => {
                state.formErrors = getFormErrors(error.data);
            })
            .finally(() => {
                state.submitting = false;
            });
    }
};
</script>
