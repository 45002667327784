<template>
    <div>
        <ais-stats>
            <template #default="{ nbHits }">
                <div class="mb-4 flex justify-between">
                    <div class="text-grind">
                        FILTER BY ({{ nbHits }} RESULTS)
                    </div>
                    <ais-clear-refinements>
                        <template #default="{ canRefine, refine, createURL }">
                            <a
                                :href="createURL()"
                                :class="{
                                    'pointer-events-none no-underline opacity-70':
                                        !canRefine,
                                }"
                                @click.prevent="refine"
                            >
                                Reset
                            </a>
                        </template>
                    </ais-clear-refinements>
                </div>
            </template>
        </ais-stats>

        <ProductFilterRefinementList
            v-show="showCategoryFilter"
            title="Availability"
            attribute="category"
            :type="type"
            :sort-by="['name']"
            operator="or"
            :expanded-default="true"
            :mobile-menu-open="mobileMenuOpen"
        />
        <ProductFilterRefinementList
            v-show="showIndustryFilter"
            title="Industry"
            attribute="industry"
            :type="type"
            :sort-by="['name']"
            operator="or"
            :expanded-default="false"
            :mobile-menu-open="mobileMenuOpen"
        />
        <ProductFilterRefinementList
            title="Product Type"
            attribute="productType"
            :type="type"
            :sort-by="['name']"
            operator="or"
            :mobile-menu-open="mobileMenuOpen"
        />
        <ProductFilterRefinementList
            title="Material"
            attribute="material"
            :type="type"
            :sort-by="['name']"
            operator="or"
            :mobile-menu-open="mobileMenuOpen"
        />
        <ProductFilterRefinementList
            title="Eco-friendly"
            attribute="ecoFriendly"
            :type="type"
            :sort-by="['name']"
            operator="or"
            :mobile-menu-open="mobileMenuOpen"
        />
        <ProductFilterRefinementList
            title="Capacity"
            attribute="capacity"
            :type="type"
            :sort-by="['name']"
            operator="or"
            :mobile-menu-open="mobileMenuOpen"
        />
        <ProductFilterRefinementList
            title="Minimum Order Quantity"
            attribute="minimumOrderQuantity"
            :type="type"
            :sort-by="['name']"
            operator="or"
            :mobile-menu-open="mobileMenuOpen"
        />
    </div>
</template>

<script setup lang="ts">
import { AisClearRefinements, AisStats } from 'vue-instantsearch/vue3/es';
import ProductFilterRefinementList from '~/components/page-building/product-filter/components/ProductFilterRefinementList.vue';

const props = defineProps<{
    type: string;
    industry?: string;
    mobileMenuOpen?: boolean;
}>();

const showCategoryFilter = computed(
    () =>
        props.type !== 'custom-printed' &&
        props.type !== 'blank' &&
        props.type !== 'design-lab',
);
const showIndustryFilter = computed(() => false);
</script>
