<template>
    <div
        class="group flex w-full flex-col rounded-xl bg-white text-grind drop-shadow-[0_0_8px_rgba(20,20,20,0.1)]"
    >
        <div class="h-[235px] rounded-t-[10px] bg-grind-100">
            <StoryblokImage
                :src="productImage.src || '/images/image-placeholder.svg'"
                :alt="productImage.alt ? productImage.alt : 'Product Image'"
                class="h-full w-full rounded-t-[10px] object-contain"
                :class="{ 'm-auto p-4': !productImage.src }"
                sizes="342px"
                :image-width="342"
                :image-height="235"
                :quality="80"
                :preload="preload"
            />
        </div>
        <div class="flex flex-1 flex-col gap-4 p-4">
            <TagGroup
                v-if="product.tags.length"
                :tags="product.tags"
                class="mt-4"
            />
            <div class="text-xl font-bold">
                {{ productName }}
            </div>
            <WysiwygContent :content="description" />
            <div class="flex flex-1 flex-col justify-end">
                <button
                    v-if="canAddToCart"
                    class="btn-secondary"
                    @click="addProductToCart(product)"
                >
                    {{ firstBtn.label }}
                </button>
                <CustomButton v-else :button="firstBtn" />
                <CustomButton :button="secondBtn" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import AlgoliaProductVariation from '~/types/AlgoliaProductVariation';
import ButtonType from '~/types/ButtonType';
import CustomButton from '~/components/page-building/components/Button.vue';
import { getProductDisplayName } from '~/utils/helpers';
import { createBuyable } from '~/types/Buyable';
import { Image } from '~/types/miscellaneous';
import useAddToCart from '~/composables/cart/useAddToCart';
import { sendDataLayerEcommerceEvent } from '~/composables/useDataLayer';

const props = defineProps<{
    product: AlgoliaProductVariation;
    industry?: string;
    preload: boolean;
}>();

const canAddToCart = computed(() => {
    return (
        props.product.category[0].includes('Blank') &&
        props.product.databaseProductId
    );
});

const firstBtn = computed<ButtonType>(() => {
    let label = '';
    let url = '';
    if (props.product.category && props.product.category.length > 0) {
        if (props.product.category[0].includes('Blank')) {
            label = 'Order Now';
            url = '#';
        } else if (props.product.category[0] === 'Custom-printed') {
            label = 'Get a Quote';
            url = `get-quote#product=${props.product.databaseProductId}`;
        } else if (props.product.category[0] === 'Roastar Design Lab') {
            label = 'Start Designing';
            url = `roastar-design-lab#product=${props.product.databaseProductId}`;
        }
    }
    return {
        label: label,
        style: 'secondary',
        url: url,
    } as ButtonType;
});

const secondBtn = computed<ButtonType>(() => {
    const label = 'Learn more';
    let url = props.industry
        ? `${props.product.slug?.toLowerCase()}&industry=${props.industry}`
        : props.product.slug?.toLowerCase();
    url = url.replace(/\/(?=[^\/]*$)/, '#sku=');
    return {
        label: label,
        style: 'tertiary',
        url: url,
    } as ButtonType;
});

const description = computed<string>(() =>
    renderRichText(props.product.description),
);

const productName = computed(() => {
    return getProductDisplayName(props.product.name, props.industry);
});

const productImage = computed(() => {
    if (props.industry && props.product.industryImages) {
        const industryImage =
            props.product.industryImages[props.industry + 'Image'];
        if (industryImage && industryImage.src) {
            return industryImage;
        }
    }
    return props.product.image;
});

const addProductToCart = async (product: AlgoliaProductVariation) => {
    const items: any = [];
    items.push({
        item_id: product.databaseProductId,
        item_name: product.name,
        index: 0,
        item_category: product.category?.length > 0 ? product.category[0] : '',
        item_category2: product.productType,
        item_category3: product.material?.length > 0 ? product.material[0] : '',
        item_list_name: product.category?.length > 0 ? product.category[0] : '',
        quantity: 1,
        is_sample: product.slug?.includes('SAMPLE') ?? false,
        color: null,
        material: product.material?.length > 0 ? product.material[0] : '',
        finish: null,
        size: null,
    });

    sendDataLayerEcommerceEvent('add_to_cart', '', {
        currency: 'USD',
        items: items,
    });

    const buyable = createBuyable({
        id: product.databaseProductId,
        image: {
            src: product.image?.src,
            alt: product.image?.alt,
        } as Image,
        name: product.name,
    });

    await useAddToCart(buyable, true);
};
</script>
